<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">EDITAR INFORMACIÓN DEL PERFIL</p>
        <span class="material-icons close_modal_icon" @click="$emit('close-modal')">close</span>
      </header>
      <section class="modal-card-body p-6">
        <div class="columns is-multiline is-centered">
          <div class="column is-11">
            <label class="label">Nombre</label>
            <input
              class="input"
              type="text"
              placeholder="Nombre"
              v-model="name"
              name="name"
              v-validate="'alpha_spaces|required'"
              data-vv-as="del nombre"
            >
            <span v-show="vverrors.has('name')" class="help is-danger">{{ vverrors.first('name') }}</span>
          </div>
          <div class="column is-11">
            <label class="label">Apellidos</label>
            <input
              class="input"
              type="text"
              placeholder="Apellidos"
              v-model="lastName"
              name="lastName"
              v-validate="'alpha_spaces|required'"
              data-vv-as="de los apellidos"
            >
            <span v-show="vverrors.has('lastName')" class="help is-danger">{{ vverrors.first('lastName') }}</span>
          </div>
          <div class="column is-11">
            <label class="label">Correo electrónico</label>
            <p>{{email}}</p>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button button_micro_dark_outlined"
          @click="$emit('close-modal')"
          :class="{ 'is-loading' : loading }"
          :disabled="loading"
        >
          CANCELAR
        </button>
        <button
          class="button button_micro_dark"
          @click="updateAction"
          :class="{ 'is-loading' : loading }"
          :disabled="loading"
        >
          CREAR
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ModalEditProfile',
  data () {
    return {
      loading: false,
      name: this.dataAdmin.name,
      lastName: this.dataAdmin.lastName,
      email: this.dataAdmin.email
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    dataAdmin: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions(['myProfileUpdate']),
    async updateAction () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        const data = {
          firstName: this.name,
          lastName: this.lastName
        }
        const resp = await this.myProfileUpdate(data)
        if (resp) {
          this.$emit('successful-update-profile')
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: 18px;
  }
  .modal-card-head, .modal-card-foot {
    background-color: white;
  }
  .modal-card-foot {
    border-top: none;
    justify-content: center;
  }

  .button {
    font-size: 14px;
    width: 40%;
  }
</style>
